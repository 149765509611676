html {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 4rem;
}

@font-face {
  font-family: Dosis;
  src: url('./assets/fonts/Dosis-VariableFont_wght.ttf');
  font-weight: 800;
}
